import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import Slider from 'react-slick';

import './styles.css';

import ReactScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";


import News from '../../components/News';
import NewsClipagem from '../../components/NewsClipagem';
import api from '../../services/api';
import { RiContactsBookLine } from 'react-icons/ri';
import { base } from '../../services/api';

const Home = (props) => {
  const [posts, setPosts] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [titleFilter, setTitleFilter] = useState("");
  const [isScrolling, setScrolling] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, //true
    speed: 3000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
    /*prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />*/
  };
  const classPosts = [
    'marginTopSmaller',
    '',
    'marginTopBigger'
  ];

  useEffect(() => {
    getPosts();
  }, []);


  const getPosts = () => {
    let slice = ``;
    if (props.location.pathname === '/spfw-25') {
      setTitleFilter("SPFW 25 Anos");
      slice = "&slice=Releases SPFW N51";
    }
    if (props.location.pathname === '/marcas') {
      setTitleFilter("Marcas");
      slice = `&slice=Releases Marcas`;
    }
    if (props.location.pathname === '/clipagem') {
      setTitleFilter("Clipagem");
      slice = `&slice=Clipagem`;
    }
    if (props.location.pathname === '/videos') {
      setTitleFilter("Videos");
      slice = `&slice=Videos`;
    }
    if (props.location.pathname === '/tag') {
      setTitleFilter("Tags");
      let queryString = props.location.search.substring(1);
      let url = new URLSearchParams(queryString);
      let tag = url.get("tag");
      slice = `&tag=${tag}`;
    }
    api(`/getposts.php?bracket=1&&slice=Releases SPFW N51`).then(res => setSliders(res.data.intro))
    api(`/getposts.php?bracket=1${slice}`).then(res => {
      let apiPosts = res.data.posts;
      if (props.location.pathname === '/marcas') {
        let queryString = props.location.search.substring(1);
        const urlParams = new URLSearchParams(queryString);
        let cards = urlParams.get("cards").split(',');
        apiPosts = apiPosts.filter(p => p.cards.some(c => cards.includes(c)));
      }
      if (props.location.pathname !== '/') {
        setPosts(apiPosts);
      }
      setScrolling(true);
    });
  }

  return (
    <>
      <div className="divFixed">
        <Slider {...settings} className="slider-home">
          {sliders.map((slider, key) => (
            <div key={key} className="sliderHomeContent">
              <img src={slider.pics.length > 0 ? `${base}/${slider.pics[0]?.pic_h}` : ''} alt={slider.title} />
              <div className="box-slider-fixed">
                <div className="box-slider-fixed-bg">
                  <h1>{slider.title}</h1>
                  <p>{slider.description}</p>
                  <p className="mediaRoom">Media Room</p>
                </div>
              </div>
            </div>
          ))}

        </Slider>
      </div>
      <div className="resultGray">
        <div className="container">
          <ReactScrollIntoViewIfNeeded active={isScrolling} >
            <div className="resultFilter">
              {titleFilter !== '' &&
                <h4>Filtrado por: {titleFilter}</h4>
              }
            </div>
          </ReactScrollIntoViewIfNeeded>

          {props.location.pathname !== '/clipagem' &&
            <Row>
              <Col sm={12}>
                <div className="boxResults">
                  <Row>
                    {posts.map((post, index) => (
                      <Col key={index} sm={4} className={classPosts[index % 3]}>
                        <News post={post} />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          }
          {props.location.pathname === '/clipagem' &&
            <Row>
              <Col sm={12}>
                <div className="boxResultsClipagem">
                  {posts.map((post, index) => (
                    <Row key={index}>
                      <Col sm={12}>
                        <NewsClipagem post={post} />
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
          }
        </div>
      </div>
    </>
  );
}
export default Home;