import React, { useEffect } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slider from '../../assets/images/news.jpg';

import { base } from '../../services/api';

import './styles.css';
const SliderHome = ({ pics }) => {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, //true
    speed: 1000,
    autoplaySpeed: 5000,
    arrows: false
  };

  const downloadPic = (url) => window.open(url, '_blank');
  return (
    <div>
      <Slider {...settings} className="sliderRight">
        {pics.map((pic, index) => {
          if (typeof pic.video === 'string') {
            return (
              <div key={index} className="boxSlider" >
                <div dangerouslySetInnerHTML={{ __html: pic.video }}></div>
                <div id="boxCredits">
                  <p><b>Créditos:</b> {pic.credits}</p>
                  <p><b>Legenda:</b> {pic.caption}</p>
                </div>
              </div>
            )
          } else {
            return (
              <div key={index} className="boxSlider">
                <img src={`${base}${pic.pic_h}`} alt="Foto" />
                <div id="boxCredits">
                  <p><b>Créditos:</b> {pic.credits}</p>
                  <p><b>Legenda:</b> {pic.caption}</p>
                </div>
                <button className="btnDownloadImg" onClick={() => downloadPic(`${base}${pic.pic_h}`)}>Download Imagem</button>
              </div>
            )
          }
        })}
      </Slider>
    </div>
  );
}
export default SliderHome;
