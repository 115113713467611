import React, { useEffect } from 'react';

import './styles.css';


const NewsClipagem = ({ post }) => {
  return (
    <>
      <div className="newsBoxClipagem">
        <p className="hour">{post.humanpostdate} | {post.title}</p>
        <button>{post.description}</button>
      </div>
    </>
  );
}
export default NewsClipagem;