import React, {useState} from 'react';

import { Col, Row } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.css';

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      //
    };
  }
  render() {
    return (
      <>      
              
      </>
    );
  }
}  