import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { RiCloseCircleLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import './styles.css';

const ModalFilter = ({ handleClose, cards }) => {
    const [actives, setActives] = useState([]);
    const [updated, setUpdated] = useState(true);

    const updateSelected = (card_name) => {
        let active = actives;
        if (active.includes(card_name)) {
            active = active.filter(a => a !== card_name);
        } else {
            if (active.length < 5) {
                active.push(card_name);
            }
        }
        setUpdated(!updated);
        setActives(active);
    }
    const filterMarcas = () => {
        let url = actives.join(',');
        window.location.href = `/marcas?cards=${url}`;
        handleClose();
    }

    return (
        <div id="modalFilters">
            <div id="headerModal">
                <h3>Filtrar por marca (escolha até 5)</h3>
                <button className="closeModal" onClick={handleClose}><RiCloseCircleLine /></button>
            </div>
            <div id="modalBody">
                <div className="container">
                    <Row>
                        {cards.map((card, key) => (
                            <Col key={key} sm={3}>
                                <button className={`btnBrand ${actives.includes(card) ? 'active' : ''}`} onClick={() => updateSelected(card)}>{card}</button>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <button className="btnFilter" onClick={filterMarcas}>Filtrar</button>
        </div>
    );
}

export default ModalFilter;