import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import Home from './pages/Home';

const Routes = () => (
    <BrowserRouter basename="/">
        <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/spfw-25" component={Home} />
            <Route exact path="/marcas" component={Home} />
            <Route exact path="/clipagem" component={Home} />
            <Route exact path="/videos" component={Home} />
            <Route exact path="/tag" component={Home} />
        </Switch>
    </BrowserRouter>

)

export default Routes;