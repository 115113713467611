import React from 'react';
import { Col, Container, Row, NavDropdown, Form } from 'react-bootstrap';

import Modal from '../../components/Modal';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.css';

import logo from '../../assets/images/logo.png';
import ModalFilter from '../ModalFilter';
import api from '../../services/api';
import { NavLink } from 'react-router-dom';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registred: false,
      email: '',
      term: '',
      marcaCards: [],
      n51Cards: [],
      cards: [],
      menu: false,
      showModal: false,
      showModalNotRegistred: false,
      showModalLogin: false,
      showModalProfile: false,
      showModalLoginPress: false,
      showModalLoginInfluence: false,
      showOverlay: false,
      showModalFiltersBrand: false,
      showModalLoginSuccess: false
    };
  }

  componentDidMount() {
    this.checkRegistred();

    api("/getposts.php?bracket=1").then(res => {
      res.data.slices.forEach(slice => {
        if (slice.name === "Releases Marcas") {
          this.setState({
            cards: slice.cards
          });
        }
      });
    });
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  checkRegistred = () => {
    let email = localStorage.getItem('email');
    if (email) {
      let result = window.checkEmail(email);
      if (result) {
        this.setState({ registred: true });
      }
    }
  }

  handleCheckEmail = () => {
    let result = window.checkEmail(this.state.email);
    localStorage.setItem('email', this.state.email);
    if (!result) {
      this.setState({ showModalNotRegistred: true, showModalLogin: false });
    } else {
      this.setState({ registred: true, showModalLogin: false })
    }
  }

  handleScroll = (e) => {
    let header = document.querySelector('header');
    let hasFixed = header.classList.contains('fixed');

    if (window.scrollY > 36) {
      if (!hasFixed) {
        header.classList.add('fixed');
      }
    } else {
      if (hasFixed) {
        header.classList.remove('fixed');
      }
    }
  }

  handleShowMenu = () => {
    let menu = this.state.menu ? false : true;
    this.setState({ menu });
    let b = document.querySelector('body');
    if (menu) {
      b.style.overflow = 'hidden';
    } else {
      b.style.overflow = 'auto';
    }
  }

  openModal = (type) => {
    this.setState({ showModalFiltersBrand: true, showOverlay: true })
  }
  handleClose = () => this.setState({
    showModalFiltersBrand: false, showOverlay: false
  });
  redirectFW = () => {
    window.location.href = "/spfw-25";
  }



  render() {
    const { showModal, showModalNotRegistred, showModalLogin, showModalProfile, showModalLoginPress, showModalLoginInfluence, showOverlay, showModalFiltersBrand, showModalLoginSuccess } = this.state
    return (
      <>
        {showModal && (
          <div id="modal" className="dark">
            <button className="closeModal" onClick={() => this.setState({ showModal: !showModal })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={{ span: 10, offset: 1 }}>
                  <p>Clique em 'Faça seu Login' na página e complete o processo de registro para ter acesso ao conteúdo.</p>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showModalLogin &&
          <div id="modal" className="modalLogin">
            <button className="closeModal" onClick={() => this.setState({ showModalLogin: !showModalLogin })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={{ span: 4, offset: 4 }}>
                  <p>Digite seu e-mail profissional?</p>
                  <Form className="formLogin">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="E-mail" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                    </Form.Group>
                    <button type="button" className="btnSend" onClick={() => this.handleCheckEmail()}>Continuar</button>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        }
        {showModalProfile && (
          <div id="modal" className="modalLogin">
            <button className="closeModal" onClick={() => this.setState({ showModalProfile: !showModalProfile })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <p>Em qual perfil você se classifica?</p>
                  <button className="btnSend Profile" onClick={() => this.setState({ showModalLoginPress: true, showModalProfile: false })}>IMPRENSA</button>
                  <button className="btnSend Profile" onClick={() => this.setState({ showModalLoginInfluence: true, showModalProfile: false })}>INFLUENCIADOR</button>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showModalLoginPress && (
          <div id="modal" className="modalLogin">
            <button className="closeModal" onClick={() => this.setState({ showModalLoginPress: !showModalLoginPress })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <div className="boxFormLogin">
                    <p>Login de imprensa</p>
                    <Form>
                      <Form.Group>
                        <Form.Label for="name">Nome:</Form.Label>
                        <Form.Control type="text" id="name" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="office">Cargo:</Form.Label>
                        <Form.Control type="text" id="office" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="vehicle">Veículo:</Form.Label>
                        <Form.Control type="text" id="vehicle" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>E-mail:</Form.Label>
                        <Form.Control value="lucas@imaxinformatica.com.br" readOnly />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="celphone">Celular:</Form.Label>
                        <Form.Control type="text" id="celphone" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="city">Cidade/UF:</Form.Label>
                        <Form.Control type="text" id="city" />
                      </Form.Group>
                      <button className="btnSend Black" onClick={() => this.setState({ showModalLoginSuccess: true, showModalLoginPress: false })}>Enviar</button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showModalLoginInfluence && (
          <div id="modal" className="modalLogin">
            <button className="closeModal" onClick={() => this.setState({ showModalLoginInfluence: !showModalLoginInfluence })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <div className="boxFormLogin">
                    <p>Login de influencer</p>
                    <Form>
                      <Form.Group>
                        <Form.Label for="name">Nome:</Form.Label>
                        <Form.Control type="text" id="name" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="instagram">Instagram:</Form.Label>
                        <Form.Control type="text" id="instagram" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="email">E-mail:</Form.Label>
                        <Form.Control type="email" id="email" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="celphone">Celular:</Form.Label>
                        <Form.Control type="text" id="celphone" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="city">Cidade/UF:</Form.Label>
                        <Form.Control type="text" id="city" />
                      </Form.Group>
                      <button className="btnSend Black" onClick={() => this.setState({ showModalLoginSuccess: true, showModalLoginInfluence: false })}>Enviar</button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showModalNotRegistred && (
          <div id="modal">
            <button className="closeModal" onClick={() => this.setState({ showModalNotRegistred: false })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={{ span: 10, offset: 1 }}>
                  <p>Se e-mail ainda não está na lista de imprensa credenciada para cobertura da SPFW.</p>
                  <p>Para pedir acesso, mande um e-mail para <b>spfw@mktmix.com.br</b></p>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showOverlay && (
          <div id="overlay"></div>
        )}
        {showModalFiltersBrand && (
          <ModalFilter cards={this.state.cards} handleClose={this.handleClose} />
        )}
        <header>
          <div id="headerBox" className="container">
            <a href="/">
              <img src={logo} alt="SPFW N51" id="logo" />
            </a>
            <button id="toggleMenu" onClick={() => this.handleShowMenu()}>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
            </button>
            <ul id="mainMenu" className={this.state.menu ? 'show' : 'hide'}>
              {this.state.registred &&
                <>
                  <li>
                    <NavDropdown title="Releases e fotos&nbsp;•" id="nav-dropdown">
                      <NavDropdown.Item eventKey="4.1" onClick={() => this.openModal('release-marcas')}>Marcas</NavDropdown.Item>
                      <NavDropdown.Item eventKey="4.2" onClick={this.redirectFW}> SPFW N51</NavDropdown.Item>
                    </NavDropdown>
                  </li>
                  <li><a href="/videos">Vídeos &nbsp;•<span></span></a></li>
                  <li><a href="/clipagem">Clipagem &nbsp;<span></span></a></li>
                </>
              }
              {!this.state.registred &&
                <li>
                  <button className="login" onClick={() => this.setState({ showModalLogin: true })}>
                    Faça seu login
                  </button>
                </li>
              }
            </ul>
          </div>
        </header>
      </>
    );
  }
}