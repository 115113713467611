import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { RiCloseCircleLine, RiContrastDropLine } from 'react-icons/ri';

import Slider from '../Slider';

import './styles.css';

import newsTwo from '../../assets/images/news.jpg';
import user from '../../assets/images/usert.png';
import { useEffect } from 'react';
import { base } from '../../services/api';
const News = ({ post }) => {
  const [showModalSlider, setShowModalSlider] = useState(false);
  const [firstPic, setFirstPic] = useState('');
  const [tags, setTags] = useState([]);
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    console.log(post);
    if (post.tags) {
      if (post.tags.length > 0) {
        setTags(post.tags);
      }
    }
    if (post.pics) {
      if (post.pics.length > 0) {
        if (typeof post.pics[0].video === 'string') {
          setFirstPic(post.pics[0].video);
          setIsVideo(true);
        } else {
          setFirstPic(base + post.pics[0].pic_h);
          setIsVideo(false);
        }
      }
    }

  }, [post]);

  const filterTag = (tag) => {
    window.location.href = `/tag?tag=${tag}`;
  }
  return (
    <>
      {isVideo &&
        <>
          <div className="newsBox video">
            <div dangerouslySetInnerHTML={{ __html: firstPic }}></div>
            <div className="newsCategory"><button onClick={() => setShowModalSlider(true)}>{post.title}</button></div>
          </div>
          <div className="newsContent">
            <button onClick={() => setShowModalSlider(true)}>{post.description}</button>
          </div>
        </>
      }
      {!isVideo &&
        <>
          <div className="newsBox">
            <img onClick={() => setShowModalSlider(true)} src={firstPic} className="newsImg" />
          </div>
          <div className="newsCategory"><button onClick={() => setShowModalSlider(true)}>{post.title}</button></div>
          <div className="newsContent">
            <button onClick={() => setShowModalSlider(true)}>{post.description}</button>
          </div>
        </>
      }
      {showModalSlider && (
        <div id="modalSlider">
          <button className="closeModal" onClick={() => setShowModalSlider(false)}><RiCloseCircleLine /></button>
          <div className="boxDivider" id="bgBlack">
            <div className="container">
              <Slider pics={post.pics} />
            </div>
          </div>
          <div className="boxDivider">
            <div className="container">
              <div className="boxContentModal">
                <div className="boxTags">
                  {tags.map((tag, key) => (
                    <button key={key} onClick={() => filterTag(tag)}>{tag}</button>
                  ))}
                </div>
                <div className="boxContent">
                  <p className="titleNews">
                    {post.title}
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: post.contents }}></p>
                  {/* <div className="aboutContact">
                    <img src={user} alt="Foto do contato" />
                    <div className="infoContact">
                      <p><b>Vinicius Oliveira</b></p>
                      <span>vinicius@empresa.com.br</span>
                      <p>(11) 99999-9999</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

}
export default News;